<template>
    <TransitionRoot
        as="template"
        :show="open">
        <Dialog
            as="div"
            class="relative z-[55]"
            @close="open = false">
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-300 sm:duration-500"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-300 sm:duration-500"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                            @after-enter="() => teleportNotificationArea = true"
                            @before-leave="() => teleportNotificationArea = false">
                            <DialogPanel
                                id="notificationAreaRoot"
                                class="pointer-events-auto w-screen"
                                :class="{'max-w-sm': size === 'sm', 'max-w-md': size === 'md', 'max-w-2xl': size === 'lg', 'max-w-4xl': size === 'xl'}">
                                <div class="flex h-full flex-col overflow-y-auto bg-white dark:bg-gray-900 shadow-xl">
                                    <div class="px-4 sm:px-6 py-6 bg-white dark:bg-gray-900 border-b border-gray-200">
                                        <div class="flex items-start justify-between">
                                            <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                                                {{ title }}
                                            </DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button
                                                    tabindex="-1"
                                                    type="button"
                                                    class="relative rounded-md bg-white dark:bg-gray-900 text-gray-700 dark:text-white hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:ring-offset-gray-900"
                                                    @click=" open=false">
                                                    <span class="absolute -inset-2.5" />
                                                    <span class="sr-only">Close panel</span>
                                                    <Icon
                                                        name="heroicons:x-mark"
                                                        class="h-6 w-6"
                                                        aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <p
                                                class="text-sm text-gray-700 dark:text-gray-300">
                                                {{ description }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                        <slot />
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    description: {
        type: String,
        default: ''
    },
    modelValue: {
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        default: 'lg'
    },
})

const emit = defineEmits(['update:modelValue'])

const open = computed({
    get: () => !!props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

const teleportNotificationArea = useState('teleportNotificationArea')

</script>

<style scoped>

</style>